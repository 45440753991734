import { FC, useEffect, useMemo, useState } from "react";
import { Transaction } from "api/transactions/types";
import { Button } from "components";
import { Option, Select } from "components/Select/Select";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

/**
 * This component is used to filter the transaction data.
 * @example
 * ```tsx
 * <TransactionsFilter
 *  transactionsData={transactionsData}
 * onFilter={(filteredData) => setFilteredData(filteredData)}
 * />
 * ```
 */
type TransactionsFilterProps = {
  /**
   * The transaction data to be filtered
   */
  transactionsData: Transaction[];
  /**
   * This function will be called when the user applies the filters.
   * @param filteredData The filtered data
   */
  onFilter: (filteredData: Transaction[]) => void;
  /**
   * The filter header
   * @example
   * ```tsx
   * filterHeader = "Filter transactions by:"
   * ```
   */
  filterHeader?: string;
};

export const TransactionsFilter: FC<TransactionsFilterProps> = ({
  transactionsData,
  onFilter,
  filterHeader,
}) => {
  const { t, i18n } = useModifiedTranslation();

  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<
    Option[]
  >([]);
  const [selectedSecurityNames, setSelectedSecurityNames] = useState<Option[]>(
    []
  );

  const [
    filteredDataByBoth,
    filteredDataBySecurityName,
    filteredDataByTransactionType,
  ] = useMemo(() => {
    if (!transactionsData) return [[], [], []];

    const filteredDataByBoth = transactionsData.filter((transaction) => {
      const isTransactionTypeMatch =
        !selectedTransactionTypes.length ||
        selectedTransactionTypes.some(
          (type) => type.value === transaction.type.typeName || type.value === transaction.type.typeCode
        );
      const isSecurityNameMatch =
        !selectedSecurityNames.length ||
        selectedSecurityNames.some(
          (name) => name.label === transaction.securityName
        );

      return isTransactionTypeMatch && isSecurityNameMatch;
    });

    const filteredDataBySecurityName = transactionsData.filter(
      (transaction) => {
        const isSecurityNameMatch =
          !selectedSecurityNames.length ||
          selectedSecurityNames.some(
            (name) => name.label === transaction.securityName
          );

        return isSecurityNameMatch;
      }
    );

    const filteredDataByTransactionType = transactionsData.filter(
      (transaction) => {
        const isTransactionTypeMatch =
          !selectedTransactionTypes.length ||
          selectedTransactionTypes.some(
            (type) => type.value === transaction.type.typeName || type.value === transaction.type.typeCode
          );

        return isTransactionTypeMatch;
      }
    );

    return [
      filteredDataByBoth,
      filteredDataBySecurityName,
      filteredDataByTransactionType,
    ];
  }, [transactionsData, selectedTransactionTypes, selectedSecurityNames]);

  useEffect(() => {
    onFilter(filteredDataByBoth || []);
  }, [filteredDataByBoth, onFilter]);

  type OptionWithCount = {
    id: string;
    label: string;
    value: string;
    count: number;
  };

  const { transactionTypes, securityNames } = useMemo<{
    transactionTypes: OptionWithCount[];
    securityNames: OptionWithCount[];
  }>(() => {
    const transactionTypes = filteredDataBySecurityName?.map((transaction) => {
      const typeName = transaction.type.typeName;
      const typeCode = transaction.type.typeCode;
      const translatedName = transaction.type.typeNamesAsMap && transaction.type.typeNamesAsMap[i18n.language.replace("-", "_")];
      return {
        id: typeCode || typeName,
        label: translatedName || typeName,
        value: typeCode || typeName
      };
    });
  
    const securityNames = filteredDataByTransactionType?.map((transaction) => ({
      id: transaction.securityName,
      label: transaction.securityName,
      value: transaction.securityName,
    }));
  
    const columnToOptions = (column: Option[] | undefined): OptionWithCount[] =>
      !column
        ? []
        : Array.from(new Set(column.map((item) => item.value)))
            .map((value) => {
              const item = column.find((item) => item.value === value);
              return item ? { ...item, count: column.filter((t) => t.value === value).length } : null;
            })
            .filter((item): item is OptionWithCount => item !== null)
            .sort((a, b) => b.count - a.count);
  
    const transactionTypeOptions = columnToOptions(transactionTypes);
    const securityNameOptions = columnToOptions(securityNames);
  
    return {
      transactionTypes: transactionTypeOptions,
      securityNames: securityNameOptions,
    };
  }, [filteredDataBySecurityName, filteredDataByTransactionType, i18n]);


  return (
    <div
      id="transactionsFilter"
      className="flex flex-col gap-4 w-full"
    >
      {filterHeader && (
        <div className="text-sm font-normal">{filterHeader}</div>
      )}
      <div className="grid flex-wrap grid-cols-1 sm:grid-cols-3 gap-2 -mt-3">
        <div className="w-full">
          <Select
            label={t("transactionFilter.transactionType")}
            value={selectedTransactionTypes}
            options={transactionTypes}
            onChangeMultiple={setSelectedTransactionTypes}
            selectMultiple
          />
        </div>
        <div className="w-full">
          <Select
            label={t("transactionFilter.securityName")}
            value={selectedSecurityNames}
            options={securityNames}
            onChangeMultiple={setSelectedSecurityNames}
            selectMultiple
          />
        </div>
        <div className="place-self-end sm:place-self-start pb-[1]">
          <Button
            onClick={() => {
              setSelectedTransactionTypes([]);
              setSelectedSecurityNames([]);
            }}
            disabled={
              !selectedTransactionTypes.length && !selectedSecurityNames.length
            }
            variant="Secondary"
          >
            {t("transactionFilter.resetFilter")}
          </Button>
        </div>
      </div>
    </div>
  );
};
